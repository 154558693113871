





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { Bank, WriteBank } from '@/modules/bank/types';
import { CHANGE_CONTACT } from '@/modules/contact/store';
import { GET_BY_ID, UPDATE } from '@/modules/bank/store';
import { MapStateToWrite } from '@/modules/bank/model';
import { Contact, WriteContact } from '@/modules/contact/types';
import { MapContactToWriteContact } from '@/modules/contact/model';

const Bank = namespace('bank');
const Contact = namespace('contact');

@Component({})
export default class UpdateBankDialog extends Vue {
  @Prop({ type: Object }) value!: Contact;
  @Prop({ type: Boolean, default: false }) dialog!: boolean;

  @Bank.Action(UPDATE) updateBank!: (params: WriteBank) => Promise<ApiResponse>;
  @Contact.Action(CHANGE_CONTACT) updateContact!: (params: WriteContact) => Promise<ApiResponse>;
  @Bank.Getter(GET_BY_ID) getBank!: (bank_id: string) => Bank | undefined;

  error: any = null;
  bank: WriteBank & WriteContact | null = null;

  get iDialog() {
    return this.dialog;
  }

  set iDialog(value) {
    this.$emit('update:dialog', value);
  }

  @Watch('value', { immediate: true })
  onValue(value: Contact) {
    if (!value) return;

    const bank = this.getBank(value.id);

    if (!bank) return;

    this.bank = {
      ...MapContactToWriteContact(value),
      ...MapStateToWrite(bank)
    };
    // @ts-ignore
    this.$refs.form.reset();
    this.error = null;
  }

  async save() {
    if (!this.bank) return {};

    this.error = null;

    const { error } = await this.updateContact(this.bank);
    const { error: bankError } = await this.updateBank(this.bank);

    return { error: error || bankError };
  }
}
