import { Bank, WriteBank } from '@/modules/bank/types';
import uuid from 'uuid/v4';

export const createBank = (bank_id: string = uuid()): WriteBank => ({
  bank_id,
  name: '',
  bic: '',
  bank_code: ''
});

export const MapStateToWrite = (bank: Bank): WriteBank => ({
  bank_id: bank.id,
  name: bank.name,
  bic: bank.bic,
  bank_code: bank.bankCode
});
